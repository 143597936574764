import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  ChangePasswordDto,
} from './dto/pifagor-api.dto';
import { SignupInfo } from '../models/signup-info';
import { CreateUserDto } from '@modules/components/users/users.service';
import ReportDto from '@modules/components/schedule/dto/report.dto';
import StudentsTeachersPairsDto from './dto/students-teachers-pairs.dto';

const API = environment.PifagorApiAddress;

@Injectable({
  providedIn: 'root'
})
export default class PifagorApiService {
  constructor(private readonly http: HttpClient) { }

  public changePassword(userId: string, body: ChangePasswordDto): Observable<void> {
    return this.http.post<void>(`${API}/auth/${userId}/change-password`, body);
  }

  public getTeacherInfoForSignup(teacherId: string): Observable<SignupInfo> {
    return this.http.get<SignupInfo>(`${API}/auth/teacher-signup/${teacherId}`);
  }

  public getClientInfoForSignup(clientId: string): Observable<SignupInfo> {
    return this.http.get<SignupInfo>(`${API}/auth/client-signup/${clientId}`);
  }

  public registerUser(body: CreateUserDto): Observable<void> {
    return this.http.post<void>(`${API}/auth/register`, body);
  }

  public restorePassword(email: string):Observable<void> {
    return this.http.get<any>(`${API}/auth/restore/${email}`);
  }

  public getReportById(reportId: string): Observable<ReportDto> {
    return this.http.get<ReportDto>(`${API}/reports/${reportId}`);
  }

  public removeReportById(reportId: string): Observable<void> {
    return this.http.delete<void>(`${API}/reports/${reportId}`);
  }

  public getStudentsTeachersPairsForClient(clientId: string): Observable<StudentsTeachersPairsDto> {
    return this.http.get<StudentsTeachersPairsDto>(`${API}/deals/client/${clientId}/students-teachers-pairs`);
  }
}
