import {
  inject,
  Pipe,
  PipeTransform
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StudentClass } from '@shared/services/students/dto/student.dto';
@Pipe({ name: 'studentClass' })
export class StudentClassPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);

  transform(value: StudentClass): string {
    return value ? this.translateService.instant('main.entity.student.class.' + value) : '-';
  }
}
