import { ContactMarketRegion } from '@shared/services/dto/pifagor-api.dto';

export enum TeacherStatus {
  CANDIDATE = 'candidate',
  TRAINEE = 'trainee',
  REGULAR = 'regular',
  RESIGNS = 'resigns',
  RESIGNED = 'resigned',
}

export default interface TeacherDto {
  id: string;
  firstName: string;
  lastName: string;
  lang: string;
  blockedAvailability: boolean;
  blockedAt: Date | null;
  notTakeNewStudentsAt: Date | null;
  isVerified: boolean;
  age: string;
  status: TeacherStatus;
  level: string;
  teacherWorkLoad: string;
  conversion: string;
  qualification: string;
  tutoringExperience: string;
  examPreparation: string;
  avatarLink?: string;
  linkToZoom?: string | null;
  marketRegion: ContactMarketRegion | null;
}
